@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./styles/buttons.css";
@import "./styles/form.css";
@import "./styles/styles.css";

html {
  scroll-behavior: smooth;
}
